import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-section" }
const _hoisted_2 = { class: "content-section__heading" }
const _hoisted_3 = { class: "content-section__caption" }
const _hoisted_4 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_font_awesome_layers = _resolveComponent("font-awesome-layers")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_font_awesome_layers, { class: "content-section__icon" }, {
        default: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, { icon: "circle" }),
          _createVNode(_component_font_awesome_icon, {
            icon: _ctx.$props.icon,
            class: "content-section__icon--symbol",
            transform: "shrink-7"
          }, null, 8, ["icon"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$props.caption), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}