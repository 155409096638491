
    import { storeKey } from '@/store'
    import { computed, defineComponent } from 'vue'
    import { useStore } from 'vuex'

    export default defineComponent({
        name: 'EducationInstitution',
        components: {},
        setup() {
            const store = useStore(storeKey)
            const eds = computed(() => store.state.educations)
            
            return {
                eds
            }
        }
    })
