import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hobby-collection" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hobby_chip = _resolveComponent("hobby-chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hobbies, (hobby, index) => {
      return (_openBlock(), _createBlock(_component_hobby_chip, {
        key: index,
        caption: hobby.Name,
        icon: hobby.Icon
      }, null, 8, ["caption", "icon"]))
    }), 128))
  ]))
}