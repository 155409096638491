
    import { storeKey } from '@/store'
    import { computed, defineComponent } from 'vue'
    import { useStore } from 'vuex'
    import Timeline from 'primevue/timeline'

    export default defineComponent({
        name: 'RolesHistory',
        components: {
            Timeline
        },
        setup() {
            const store = useStore(storeKey)
            const jobs = computed(() => store.state.jobs)

            return {
                jobs
            }
        }
    })
