import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "roles" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "roles__container" }
const _hoisted_4 = { class: "roles__title" }
const _hoisted_5 = { class: "roles__years" }
const _hoisted_6 = { class: "roles__description" }
const _hoisted_7 = { class: "roles__role" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "roles__techs" }
const _hoisted_11 = ["src", "title", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Timeline = _resolveComponent("Timeline")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Timeline, {
      value: _ctx.jobs,
      align: "alternate",
      class: "roles__timeline"
    }, {
      marker: _withCtx((slotProps) => [
        _createElementVNode("img", {
          src: slotProps.item.Logo.url,
          alt: slotProps.item.Logo.alternativeText,
          class: "roles__marker"
        }, null, 8, _hoisted_2)
      ]),
      content: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(slotProps.item.Company), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(slotProps.item.Start) + " - " + _toDisplayString(slotProps.item.End || 'Present'), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(slotProps.item.Role) + ": ", 1),
          _createElementVNode("span", {
            class: "roles__description--short",
            innerHTML: slotProps.item.Description
          }, null, 8, _hoisted_8),
          _createElementVNode("span", {
            class: "roles__description--long",
            innerHTML: slotProps.item.LongDescription
          }, null, 8, _hoisted_9)
        ]),
        _createElementVNode("div", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.item.Technologies, (tech, index) => {
            return (_openBlock(), _createElementBlock("img", {
              key: index,
              src: tech.url,
              title: tech.alternativeText,
              alt: tech.alternativeText,
              class: "roles__tech"
            }, null, 8, _hoisted_11))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}