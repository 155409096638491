
    import { storeKey } from '@/store'
    import { computed, defineComponent } from 'vue'
    import { useStore } from 'vuex'

    export default defineComponent({
        name: 'CertificationsTable',
        components: {},
        setup() {
            const store = useStore(storeKey)
            const certs = computed(() => store.state.certifications)
            
            return {
                certs
            }
        }
    })
