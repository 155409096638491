import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "social-media" }
const _hoisted_2 = { class: "link-row__text" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "link-row__anchor link-row__anchor--print" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_font_awesome_layers = _resolveComponent("font-awesome-layers")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socials, (social, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "link-row",
        key: index
      }, [
        _createVNode(_component_font_awesome_layers, { class: "link-row__icon" }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, { icon: "circle" }),
            _createVNode(_component_font_awesome_icon, {
              icon: [social.Library, social.Icon],
              class: "link-row__icon--symbol",
              transform: "shrink-7"
            }, null, 8, ["icon"])
          ]),
          _: 2
        }, 1024),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("a", {
            class: "link-row__anchor link-row__anchor--screen",
            href: social.Link,
            target: "_blank"
          }, _toDisplayString(social.Name), 9, _hoisted_3),
          _createElementVNode("div", _hoisted_4, _toDisplayString(social.PrintLink), 1)
        ])
      ]))
    }), 128))
  ]))
}