import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "references" }
const _hoisted_2 = { class: "ref__right" }
const _hoisted_3 = { class: "ref__name" }
const _hoisted_4 = { class: "ref__job" }
const _hoisted_5 = { class: "ref__email-row" }
const _hoisted_6 = { class: "ref__email" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "ref__phone-row" }
const _hoisted_9 = { class: "ref__phone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.refs, (ref, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "ref",
        key: index
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(ref.Name), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(ref.Role) + " at " + _toDisplayString(ref.Company), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_font_awesome_icon, {
              icon: "envelope",
              class: "ref__icon"
            }),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("a", {
                target: "_blank",
                href: 'mailto:' + ref.Email
              }, _toDisplayString(ref.Email), 9, _hoisted_7)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_font_awesome_icon, {
              icon: "square-phone-flip",
              class: "ref__icon"
            }),
            _createElementVNode("div", _hoisted_9, _toDisplayString(ref.Phone), 1)
          ])
        ])
      ]))
    }), 128))
  ]))
}