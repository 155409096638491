import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.modulesLoaded)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ProgressSpinner)
        ])),
    _createVNode(_component_Toast)
  ], 64))
}