
    import { storeKey } from '@/store'
    import { computed, defineComponent } from 'vue'
    import { useStore } from 'vuex'

    export default defineComponent({
        name: 'ProfileAvatar',
        components: {},
        setup() {
            const store = useStore(storeKey)

            const url = computed(() => store.state.githubUser.avatar_url)
            const details = computed(() => store.state.profileDetails)

            return {
                url,
                details
            }
        }
    })
