
    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'PrintButton',
        components: {},
        emits: ['click'],
        setup(_, { emit }) {
            return {
                print: () => {
                    emit('click')
                }
            }
        },
    })
