import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "educations" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "educations__details" }
const _hoisted_4 = { class: "educations__name" }
const _hoisted_5 = { class: "educations__institute" }
const _hoisted_6 = { class: "educations__year" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eds, (ed, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "educations__row"
      }, [
        _createElementVNode("img", {
          src: ed.Logo.url,
          alt: ed.Logo.alternativeText,
          class: "educations__logo"
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(ed.Name), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(ed.Institute), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(ed.Year), 1)
        ])
      ]))
    }), 128))
  ]))
}