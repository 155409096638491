
    import { storeKey } from '@/store'
    import { computed, defineComponent } from 'vue'
    import { useStore } from 'vuex'
    import Rating from 'primevue/rating'

    export default defineComponent({
        name: 'SkillSet',
        components: {
            Rating
        },
        setup() {
            const store = useStore(storeKey)
            const skills = computed(() => store.state.skills)
            
            return {
                skills
            }
        }
    })
