
    import { defineComponent } from 'vue'
    export default defineComponent({
        name: 'EducationInstitution',
        components: {},
        props: {
            caption: String,
            icon: String
        }
    })
