
    import { storeKey } from '@/store'
    import { computed, defineComponent } from 'vue'
    import { useStore } from 'vuex'
    import HobbyChip from './HobbyChip.vue'

    export default defineComponent({
        name: 'HobbyCollection',
        components: {
            HobbyChip
        },
        setup() {
            const store = useStore(storeKey)
            const hobbies = computed(() => store.state.hobbies)
            
            return {
                hobbies
            }
        }
    })
