
    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'ContentSection',
        components: {
        },
        props: {
            icon: String,
            caption: String,
        }
    })
