
    import ProfileAvatar from '@/components/ProfileAvatar.vue'
    import SocialMedia from '@/components/SocialMedia.vue'
    import SkillSet from '@/components/SkillSet.vue'
    import HobbyCollection from '@/components/HobbyCollection.vue'
    import ContentSection from '@/components/ContentSection.vue'
    import RolesHistory from '@/components/RolesHistory.vue'
    import EducationInstitutions from '@/components/EducationInstitutions.vue'
    import CertificationsTable from '@/components/CertificationsTable.vue'
    import PrintButton from '@/components/PrintButton.vue'
    import ReferenceList from '@/components/ReferenceList.vue'
    import InputText from 'primevue/inputtext'
    import { storeKey } from '@/store'
    import { computed, defineComponent, ref } from 'vue'
    import { useStore } from 'vuex'
    import { useToast } from 'primevue/usetoast'
    import Dialog from 'primevue/dialog'
    import Button from 'primevue/button'
    import * as converter from 'number-to-words'

    export default defineComponent({
        name: 'HomeView',
        components: {
            ProfileAvatar,
            SocialMedia,
            SkillSet,
            HobbyCollection,
            ContentSection,
            CertificationsTable,
            EducationInstitutions,
            RolesHistory,
            PrintButton,
            Dialog,
            Button,
            ReferenceList,
            InputText
        },
        setup() {
            const toast = useToast()
            const showLogin = ref(false)
            const username = ref('')
            const password = ref('')
            const store = useStore(storeKey)
            const profile = computed(() => store.state.profileDetails)
            const loggingIn = ref(false)
            const token = computed(() => store.state.jwt)

            const login = async () => {
                loggingIn.value = true

                try {
                    await store.dispatch('authenticate', {
                        username: username.value,
                        password: password.value
                    })
                    
                    await store.dispatch('loadReferences', store.state.jwt)
                    showLogin.value = false
                    toast.add({severity:'info', summary: 'Logged In', detail: 'You have now logged in, the references are now populating', life: 3000})

                } catch (e) {
                    console.log('error caught')
                    toast.add({severity:'error', summary: (e as Error).name, detail:(e as Error).message, life: 3000})
                    console.error(e)
                } finally {
                    loggingIn.value = false
                }
            }
            
            window.onkeyup = (e) => {
                if (e.altKey && e.key === 'l') {
                    showLogin.value = true
                }
            }

            const about = (about: string): string => {
                const year = new Date().getFullYear() - 2008
                const words = converter.toWords(year)
                return about.replace('{{career}}', words)
            }

            return {
                profile,
                prompt,
                login,
                loggingIn,
                showLogin,
                username,
                password,
                token,
                download: () => {
                    window.open('https://github.com/RobbieLD/robdavis.dev/raw/master/render/Rob%20Davis%20-%20CV.pdf')
                },
                about
            }
        }
    })
