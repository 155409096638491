
    import { defineComponent, onMounted, ref } from 'vue'
    import { useStore } from 'vuex'
    import { storeKey } from './store'
    import Toast from 'primevue/toast'
    import ProgressSpinner from 'primevue/progressspinner'

    export default defineComponent({
        name: 'App',
        components: {
            Toast,
            ProgressSpinner
        },
        setup() {
            const store = useStore(storeKey)
            const modulesLoaded = ref(false)
            
            
            onMounted(() => {
                Promise.all([
                    store.dispatch('loadGitHubUser'),
                    store.dispatch('loadProfile'),
                    store.dispatch('loadSocials'),
                    store.dispatch('loadSkills'),
                    store.dispatch('loadHobbies'),
                    store.dispatch('loadJobs'),
                    store.dispatch('loadCerts'),
                    store.dispatch('loadEducations')
                ]).then(() => {
                    modulesLoaded.value = true
                })
            })

            return {
                modulesLoaded
            }
        }
    })
